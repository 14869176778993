var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Odd } from "./odd";
import { mobileHighLightedMarket, myHighLightedMarketGroups } from "../../../services/sportsbook.services";
import { EventMarket } from "../../../models/sportsbook.model";
import { isMobileView } from "../../../app";
export function HighlightedMarkets(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    // console.log(props.event.eventId)
    var highlightedMarkets = props.highlightedMarkets;
    var event = props.event;
    // console.log(highlightedMarkets)
    // const event = props.event;
    var hlGroups = myHighLightedMarketGroups[event.sportSlug];
    if (isMobileView) {
        hlGroups = [mobileHighLightedMarket.selected];
    }
    if (isMobileView) {
        return highlightedMarkets ? (_jsx(_Fragment, { children: hlGroups.map(function (h, key) {
                var _market = highlightedMarkets[h[Number(event.bettingPhase) === 0 ? 'pre' : 'live']];
                return _jsx(Odd, { event: event, market: _market }, "odd-" + key);
            }) }, void 0)) : (_jsx(_Fragment, {}, void 0));
    }
    else {
        return highlightedMarkets ? (_jsx(_Fragment, { children: hlGroups.map(function (h, key) {
                var _a;
                var _market = highlightedMarkets[h[Number(event.bettingPhase) === 0 ? 'pre' : 'live']];
                return _jsxs(React.Fragment, { children: [key != 0 &&
                            _jsx("li", __assign({ className: "multi-column-base" }, { children: _market && ((_a = _market.specialOddsValue) !== null && _a !== void 0 ? _a : '-') }), "colum-base" + key), _jsx("li", { children: _jsx(Odd, { event: event, market: _market }, void 0) }, "odd-key-" + key)] }, "highlightedMarkets-" + key);
            }) }, void 0)) : (_jsx(_Fragment, {}, void 0));
    }
}
export function HighlightedMarketsCard(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    // console.log(props.event.eventId)
    var highlightedMarkets = props.highlightedMarkets;
    var event = props.event;
    // console.log(highlightedMarkets)
    // const event = props.event;
    var selectedMarket = new EventMarket();
    // @ts-ignore
    for (var _i = 0, _a = Object.entries(highlightedMarkets); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], val = _b[1];
        var ma = highlightedMarkets[key];
        if (ma.marketStatus < 2 && ma.marketStatus !== 0) {
            selectedMarket = ma;
            break;
        }
    }
    return selectedMarket.marketId ? (_jsx("div", __assign({ className: "market-group-holder-bc left-menu-market" }, { children: _jsx(Odd, { event: event, showOutcomeName: true, market: selectedMarket, marketOutcome: selectedMarket.marketOutcome }, void 0) }), void 0)) : (_jsx(_Fragment, {}, void 0));
}
