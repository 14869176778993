var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import './assets/css/bootstrap/bootstrap-grid.min.css';
import './assets/css/custom.css';
import './assets/css/media968.css';
import AppRoutes from './app/Routes';
import SportsBookService, { getSportSummery } from "./services/sportsbook.services";
import { AuthContext, loginSubject } from "./store/login.store";
import { restoreLogin } from "./services/login.services";
import ModalService from "./components/modal/modal";
import Modal from "react-modal";
import { isOffline, isOnline } from "./lib/misc.functions";
export var inProgress = { state: false, };
var width = window.innerWidth;
export var isMobileView = width < 986 ? true : false;
var timer_sportsSummery;
function App() {
    var _a = useState(false), isAuthenticated = _a[0], setIsAuthenticated = _a[1];
    if (isMobileView) {
        document.getElementsByTagName('html')[0].classList.add('is-mobile');
        document.getElementsByTagName('html')[0].classList.remove('is-web');
    }
    useEffect(function () {
        timer_sportsSummery = setInterval(function () {
            getSportSummery();
        }, 120000);
        return function () {
            clearInterval(timer_sportsSummery);
        };
    }, []);
    useEffect(function () {
        window.addEventListener('resize', resizeWindow);
        return function () {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);
    useEffect(function () {
        window.addEventListener("online", isOnline, false);
        window.addEventListener("offline", isOffline, false);
        return function () {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);
    var resizeWindow = function () {
        var width = window.innerWidth;
        var nowIsMobileView = width < 986 ? true : false;
        if (isMobileView !== nowIsMobileView) {
            window.location.reload();
        }
    };
    useEffect(function () {
        var loginSubject_subscription;
        if (!isAuthenticated) {
            // eğer token varsa otomatik auth icin wss i restore etmek icin var.
            restoreLogin();
            loginSubject_subscription = loginSubject.subscribe(function (member) {
                if (member.id !== undefined && member.status === 1) {
                    setIsAuthenticated(true);
                }
            });
        }
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    // @ts-ignore
    Modal.setAppElement(document.getElementById('root'));
    return (_jsx(_Fragment, { children: _jsx(AuthContext.Provider, __assign({ value: { isAuthenticated: isAuthenticated, setIsAuthenticated: setIsAuthenticated } }, { children: _jsx(SportsBookService, { children: _jsx(ModalService, { children: _jsx(AppRoutes, {}, void 0) }, void 0) }, void 0) }), void 0) }, void 0));
}
export default App;
