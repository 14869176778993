var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { popularCompetition, sportsbookFilter, sportsbookList, sportsSummery, sportsSummerySubject } from "../../store/sportsbook.store";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../store/login.store";
import { useForceUpdate } from "../../lib/misc.functions";
import { generateCompetitionGroupList, mobileHighLightedMarket, setFilterCompetitions } from "../../services/sportsbook.services";
import _ from "lodash";
import { HighlightedMarkets, HighlightedMarketsCard } from "../../pages/sportsbook/components/highlightedmarkets";
import { SearchBar } from "../../pages/home/home";
import { isMobileView } from "../../app";
import { highLightedMarketCategories } from "../../definitions/constants/market-categories.constant";
import { betSlipActionSubject } from "../../store/betslip.store";
export default function SideMenu(props) {
    var openPage = window.location.href;
    var forceUpdate = useForceUpdate();
    var pathname = window.location.pathname;
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var width = window.innerWidth;
    var eventType = props.eventType;
    var sportSlug = props.sportSlug;
    var paramsCompetitionId = props.competitionId;
    var _a = useState(null), selectedComp = _a[0], setSelectedComp = _a[1];
    var _b = useState(null), selectedLiveComp = _b[0], setSelectedLiveComp = _b[1];
    var _c = useState(null), selectedSport = _c[0], setSelectedSport = _c[1];
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            forceUpdate();
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    var setFilter = function (competitionId, sportSlug, eventType) {
        sportsbookFilter.filterCompetition = [];
        setFilterCompetitions(competitionId);
        forceUpdate();
        navigate("/sports/" + sportSlug + "/" + eventType);
    };
    useEffect(function () {
        var sportsSummerySubject_subscription = sportsSummerySubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            sportsSummerySubject_subscription.unsubscribe();
        };
    }, []);
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            return "/sports/" + sportSlug + '/uzunvadeli';
            // document.body.style.overflow = 'unset';
        }
        else {
            return "/sports/" + sportSlug + '/tumu';
            // document.body.style.overflow = 'unset';
        }
    };
    var isActive = function (sportSlug, eventType) {
        if (window.location.pathname.indexOf(sportSlug) !== -1) {
            if (eventType === '2' && window.location.pathname.indexOf('uzunvadeli') !== -1) {
                return 'active';
            }
            if (eventType === '1' && (window.location.pathname.indexOf('tumu') !== -1 || window.location.pathname.indexOf('canli') !== -1)) {
                return 'active';
            }
        }
        return '';
    };
    var popularCompetitions = popularCompetition;
    useEffect(function () {
        if (sportSlug) {
            setSelectedSport(sportSlug + '-' + (eventType == 'uzunvade' ? '2' : '1'));
            var sportsCompetitions = generateCompetitionGroupList(_.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                if (sportSlug === el.sportSlug) {
                    if ((eventType === 'tumu' && Number(el.eventType) === 1) ||
                        (eventType === 'canli' && Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                        (eventType === 'uzunvadeli' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                        return el;
                    }
                }
                else if (sportSlug == null && Number(el.eventType) !== 2) {
                    return el;
                }
            }), [sportSlug]);
            if (paramsCompetitionId) {
                for (var _i = 0, sportsCompetitions_1 = sportsCompetitions; _i < sportsCompetitions_1.length; _i++) {
                    var sportsCompetition = sportsCompetitions_1[_i];
                    for (var _a = 0, _b = Object.entries(sportsCompetition.competitions); _a < _b.length; _a++) {
                        var _c = _b[_a], key = _c[0], value = _c[1];
                        // @ts-ignore
                        if (value[0].competitionId == paramsCompetitionId) {
                            setSelectedComp(sportsCompetition);
                            break;
                        }
                    }
                }
            }
            else {
                setSelectedComp(sportsCompetitions[0]);
            }
        }
    }, []);
    return (_jsxs(_Fragment, { children: [!isMobileView && _jsxs("div", __assign({ className: "left-menu-container" }, { children: [_jsx("div", __assign({ className: "left-menu-search" }, { children: _jsx(SearchBar, {}, void 0) }), void 0), _jsxs("div", __assign({ className: "second-tabs-bc" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                    navigate('/sports/futbol/canli');
                                }, className: "tab-bc selected-underline ".concat(window.location.href.includes("sports") && window.location.href.includes("canli") ? 'active' : ''), title: "" }, { children: [_jsx("span", { children: "CANLI" }, void 0), _jsxs("span", { children: ["(", sportsbookList.filter(function (el) {
                                                return Number(el.isLiveEvent) === 1;
                                            }).length, ")"] }, void 0)] }), void 0), _jsxs("div", __assign({ onClick: function () {
                                    navigate('/sports/futbol/tumu');
                                }, className: "tab-bc selected-underline ".concat(window.location.href.includes("sports") && window.location.href.includes("tumu") ? 'active' : ''), title: "" }, { children: [_jsx("span", { children: "MA\u00C7 \u00D6NCES\u0130" }, void 0), _jsxs("span", { children: ["(", sportsbookList.filter(function (el) {
                                                return Number(el.isLiveEvent) === 0;
                                            }).length, ")"] }, void 0)] }), void 0)] }), void 0), eventType != 'canli' && _jsx("div", __assign({ className: "pp-sport-list-holder-bc" }, { children: _jsxs("div", __assign({ className: "left-menu-scroll" }, { children: [_jsx("div", __assign({ className: "sp-sub-list-bc popular-matches   " }, { children: _jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "POP\u00DCLER MA\u00C7LAR" }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc bc-i-tournament" }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: "POP\u00DCLER MA\u00C7LAR" }), void 0) }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right" }, void 0)] }), void 0) }), void 0), sportsSummery.map(function (item, key) {
                                    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                        // console.log(params)
                                        if (item.sportSlug === el.sportSlug) {
                                            if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                                (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                                (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                                return el;
                                            }
                                        }
                                        else if (item.sportSlug == null) {
                                            return el;
                                        }
                                    });
                                    var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, [item.sportSlug]);
                                    return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(item.sportSlug, " ").concat(selectedSport === item.sportSlug + "-" + item.eventType ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                    if (selectedSport == item.sportSlug + "-" + item.eventType) {
                                                        setSelectedSport('');
                                                    }
                                                    else {
                                                        setSelectedSport(item.sportSlug + "-" + item.eventType);
                                                    }
                                                    // forceUpdate();
                                                }, className: "sp-s-l-head-bc", title: item.sportSlug }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc bc-i-".concat(item.sportSlug) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsxs("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: [item.sportName, " ", item.eventType === '2' ? 'UV' : '', " "] }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: sportsCompetitions.length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: sportsCompetitions.map(function (itemComp, key) {
                                                    return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedComp != null && selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                    console.log(itemComp);
                                                                    if (selectedComp == null) {
                                                                        setSelectedComp(itemComp);
                                                                    }
                                                                    else {
                                                                        if (selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId) {
                                                                            setSelectedComp(null);
                                                                        }
                                                                        else {
                                                                            setSelectedComp(itemComp);
                                                                        }
                                                                    }
                                                                    forceUpdate();
                                                                }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: itemComp.count }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                                    return (_jsxs("div", __assign({ onClick: function () {
                                                                            setFilter(comp[1][0].competitionId, item.sportSlug, item.eventType === '2' ? 'uzunvadeli' : 'tumu');
                                                                        }, className: "sp-sub-list-bc" }, { children: [_jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "" }, { children: [_jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0)] }), void 0), _jsx("div", { className: "sp-s-l-b-content-bc" }, void 0)] }), "com-" + key));
                                                                }) }), void 0)] }), "eventSporMenu2".concat(key));
                                                }) }), void 0)] }), "sportsCompetitions-" + key);
                                })] }), void 0) }), void 0), eventType == 'canli' && _jsx(_Fragment, { children: _jsx("div", __assign({ className: "pp-sport-list-holder-bc" }, { children: _jsx("div", __assign({ className: "left-menu-scroll" }, { children: sportsSummery.map(function (item, key) {
                                    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                        // console.log(params)
                                        if (item.sportSlug === el.sportSlug) {
                                            if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                                (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                                (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                                return el;
                                            }
                                        }
                                        else if (item.sportSlug == null) {
                                            return el;
                                        }
                                    });
                                    var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, [item.sportSlug]);
                                    return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(item.sportSlug, " ").concat(selectedSport === item.sportSlug + "-" + item.eventType ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                    if (selectedSport == item.sportSlug + "-" + item.eventType) {
                                                        setSelectedSport('');
                                                    }
                                                    else {
                                                        setSelectedSport(item.sportSlug + "-" + item.eventType);
                                                    }
                                                }, className: "sp-s-l-head-bc", title: item.sportSlug }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc bc-i-".concat(item.sportSlug) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsxs("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: [item.sportName, " ", item.eventType === '2' ? 'UV' : '', " "] }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: sportsCompetitions.length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: sportsCompetitions.map(function (itemComp, key) {
                                                    return _jsx(_Fragment, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                            var customGroupHeader = comp[0] + '' + itemComp.competitionGroupName + '_' + itemComp.sportId;
                                                            return (_jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedLiveComp == customGroupHeader ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                            if (selectedLiveComp == null) {
                                                                                setSelectedLiveComp(customGroupHeader);
                                                                            }
                                                                            else {
                                                                                if (selectedLiveComp == customGroupHeader) {
                                                                                    setSelectedLiveComp(null);
                                                                                }
                                                                                else {
                                                                                    setSelectedLiveComp(customGroupHeader);
                                                                                }
                                                                            }
                                                                            forceUpdate();
                                                                        }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsxs("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: [_jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0), _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0)] }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: comp[1].map(function (event, __key) {
                                                                            return _jsxs("div", __assign({ className: "market-game-section ", onClick: function () {
                                                                                    // eventDetailSubject.next(event)
                                                                                    navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                                                                                } }, { children: [_jsxs("p", __assign({ className: "market-game-team" }, { children: [_jsx("span", __assign({ className: "market-game-team-name ellipsis" }, { children: event.eventParticipant[0].participantName }), void 0), event.currentScore &&
                                                                                                _jsx("b", __assign({ className: "market-game-odd" }, { children: event.currentScore.split(':')[0] }), void 0)] }), void 0), _jsxs("p", __assign({ className: "market-game-team" }, { children: [_jsx("span", __assign({ className: "market-game-team-name ellipsis" }, { children: event.eventParticipant[1].participantName }), void 0), event.currentScore &&
                                                                                                _jsx("b", __assign({ className: "market-game-odd" }, { children: event.currentScore.split(':')[1] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "market-game-part-container" }, { children: [_jsx("span", __assign({ className: "market-game-part" }, { children: event.matchStatus }), void 0), _jsxs("b", { children: ["+", event.marketOutcomeCount] }, void 0), _jsx("i", { className: " bc-i-favorite " }, void 0)] }), void 0), _jsxs("div", __assign({ className: "market-game-additional-info-container" }, { children: [_jsxs("span", __assign({ className: "market-game-additional-info" }, { children: [event.currentScore, ", ", event.scores && _jsxs(_Fragment, { children: ["(", event.scores.homeTeam.firstHalf, ":", event.scores.awayTeam.firstHalf, ")"] }, void 0), " ", event.matchPlayTime, "`"] }), void 0), _jsx("time", __assign({ className: "market-game-additional-info-time" }, { children: event.eventDate.split("T")[1].substring(0, 5) }), void 0)] }), void 0), _jsx(HighlightedMarketsCard, { event: event, highlightedMarkets: event.highlightedMarkets }, void 0)] }), key + "side-event-" + __key);
                                                                        }) }), void 0)] }), "eventSporMenu2".concat(key)));
                                                        }) }, void 0);
                                                }) }), void 0)] }), "sportsCompetitions" + key);
                                }) }), void 0) }), void 0) }, void 0)] }), void 0), isMobileView &&
                _jsxs(_Fragment, { children: [eventType != 'canli' && _jsx("div", __assign({ className: "pp-sport-list-holder-bc" }, { children: _jsx("div", __assign({ className: "left-menu-scroll" }, { children: sportsSummery.filter(function (el) { return el.sportSlug == sportSlug; }).map(function (item, key) {
                                    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                        // console.log(params)
                                        if (item.sportSlug === el.sportSlug) {
                                            if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                                (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                                (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                                return el;
                                            }
                                        }
                                        else if (item.sportSlug == null) {
                                            return el;
                                        }
                                    });
                                    var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, [item.sportSlug]);
                                    return _jsx(_Fragment, { children: sportsCompetitions.map(function (itemComp, key) {
                                            return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedComp != null && selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                            if (selectedComp == null) {
                                                                setSelectedComp(itemComp);
                                                            }
                                                            else {
                                                                if (selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId) {
                                                                    setSelectedComp(null);
                                                                }
                                                                else {
                                                                    setSelectedComp(itemComp);
                                                                }
                                                            }
                                                            forceUpdate();
                                                        }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("img", { width: 15, height: 15, style: { marginRight: "10px" }, src: "https://cdn.1xwon.com/images/country_icons/".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'Scotland' : itemComp.countryName, ".png") }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: itemComp.count }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: Object.entries(itemComp.competitions).map(function (comp, _key) {
                                                            return (_jsxs("div", __assign({ onClick: function () {
                                                                    sportsbookFilter.selectedCompetitionGroup = itemComp;
                                                                    navigate("/sports/" + item.sportSlug + '/tumu/' + comp[1][0].competitionId);
                                                                    window.scrollTo(0, 0);
                                                                }, className: "sp-sub-list-bc " }, { children: [_jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "" }, { children: [_jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0)] }), void 0), _jsx("div", { className: "sp-s-l-b-content-bc" }, void 0)] }), "competitions-" + _key));
                                                        }) }), void 0)] }), "eventSporMenu2".concat(key));
                                        }) }, void 0);
                                }) }), void 0) }), void 0), eventType == 'canli' && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "live-page-sections-bc ext-2" }, { children: [_jsxs("div", __assign({ className: "market-filter-bc" }, { children: [_jsx("div", __assign({ className: "market-filter-s-bc" }, { children: _jsx("div", __assign({ className: "form-control-bc select s-small filled" }, { children: _jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("select", __assign({ className: "form-control-select-bc ellipsis", onChange: function (event) {
                                                                    console.log(event.target.value);
                                                                    mobileHighLightedMarket.selected = highLightedMarketCategories[sportSlug || 'futbol'].find(function (el) { return el.live === event.target.value; });
                                                                    forceUpdate();
                                                                } }, { children: highLightedMarketCategories[sportSlug || 'futbol'].map(function (marketGroup, key) {
                                                                    return _jsx("option", __assign({ value: marketGroup.live }, { children: marketGroup.name }), void 0);
                                                                }) }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "market-filter-cell-bc" }, { children: mobileHighLightedMarket.selected.labels.map(function (el, keyH) {
                                                    return _jsx("div", __assign({ className: "market-filter-info-bc" }, { children: el }), "highlighted-" + keyH);
                                                }) }), void 0)] }), void 0), sportsSummery.filter(function (el) { return el.sportSlug == sportSlug; }).map(function (item, key) {
                                        var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                            // console.log(params)
                                            if (item.sportSlug === el.sportSlug) {
                                                if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                                    (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                                    (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                                    return el;
                                                }
                                            }
                                            else if (item.sportSlug == null) {
                                                return el;
                                            }
                                        });
                                        var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, [item.sportSlug]);
                                        return _jsx(_Fragment, { children: sportsCompetitions.map(function (itemComp, key) {
                                                return _jsx(_Fragment, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                        var customGroupHeader = comp[0] + '' + itemComp.competitionGroupName + '_' + itemComp.sportId;
                                                        return (_jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedLiveComp == customGroupHeader ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                        if (selectedLiveComp == null) {
                                                                            setSelectedLiveComp(customGroupHeader);
                                                                        }
                                                                        else {
                                                                            if (selectedLiveComp == customGroupHeader) {
                                                                                setSelectedLiveComp(null);
                                                                            }
                                                                            else {
                                                                                setSelectedLiveComp(customGroupHeader);
                                                                            }
                                                                        }
                                                                        forceUpdate();
                                                                    }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("img", { width: 15, height: 15, style: { marginRight: "10px" }, src: "https://cdn.1xwon.com/images/country_icons/".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'Scotland' : itemComp.countryName, ".png") }, void 0), _jsxs("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: [_jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0), _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0)] }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: comp[1].map(function (event, __key) {
                                                                        return _jsxs("div", __assign({ className: "c-segment-holder-bc single-g-info-bc" }, { children: [_jsxs("div", __assign({ className: "sw-container-bc ", style: { transform: "translateX(0px)" } }, { children: [_jsx("div", __assign({ className: "c-segment-holder-bc markets " }, { children: _jsxs("div", __assign({ className: "c-row-bc bordered" }, { children: [_jsxs("div", __assign({ className: "c-teams-bc", onClick: function () {
                                                                                                            navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                                                                                                        } }, { children: [_jsxs("div", __assign({ className: "c-teams-row-bc" }, { children: [_jsx("span", __assign({ className: "c-team-info-team-bc team", title: event.eventParticipant[0].participantName }, { children: event.eventParticipant[0].participantName }), void 0), event.currentScore &&
                                                                                                                        _jsx("b", __assign({ className: "c-team-info-scores-bc" }, { children: event.currentScore.split(':')[0] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "c-teams-row-bc" }, { children: [_jsx("span", __assign({ className: "c-team-info-team-bc team", title: event.eventParticipant[1].participantName }, { children: event.eventParticipant[1].participantName }), void 0), event.currentScore &&
                                                                                                                        _jsx("b", __assign({ className: "c-team-info-scores-bc" }, { children: event.currentScore.split(':')[1] }), void 0)] }), void 0)] }), void 0), _jsx(HighlightedMarkets, { style: "border:1px solid #000;", event: event, highlightedMarkets: event.highlightedMarkets }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "c-segment-holder-bc info", onClick: function () {
                                                                                                navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                                                                                            } }, { children: _jsxs("div", __assign({ className: "c-info-bc" }, { children: [_jsxs("div", __assign({ className: "c-info-content-bc" }, { children: [_jsx("i", { className: "info-live-prematch-icon-bc bc-i-live" }, void 0), _jsxs("div", __assign({ className: "c-info-score-wrapper" }, { children: [_jsx("span", __assign({ className: "c-info-score-bc" }, { children: event.matchStatus }), void 0), _jsxs("span", __assign({ className: "c-info-score-bc fixed-direction", title: "" }, { children: [event.currentScore, ", ", event.scores && _jsxs(_Fragment, { children: ["(", event.scores.homeTeam.firstHalf, ":", event.scores.awayTeam.firstHalf, ")"] }, void 0), " ", event.matchPlayTime, "`"] }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "c-info-more-bc" }, { children: _jsxs("span", __assign({ className: "c-info-icon-bc c-info-markets-count-bc" }, { children: ["+", event.marketOutcomeCount] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "swipe-fav-container-bc ", style: { transform: "translateX(60px)" } }, { children: _jsx("i", { className: "c-info-icon-bc bc-i-favorite" }, void 0) }), void 0)] }), key + "-event-side-" + __key);
                                                                        /*<div className="market-game-section "
                                                                                    onClick={() => {
                                                                                        eventDetailSubject.next(event)
                                                                                    }}>
                                                                            <p className="market-game-team">
                                                                            <span
                                                                                className="market-game-team-name ellipsis">{event.eventParticipant[0].participantName}</span>
                                                                                {event.currentScore &&
                                                                                    <b className="market-game-odd">{event.currentScore.split(':')[0]}</b>}
                                                                            </p>
                                                                            <p className="market-game-team">
                                                                            <span
                                                                                className="market-game-team-name ellipsis">{event.eventParticipant[1].participantName}</span>
                                                                                {event.currentScore &&
                                                                                    <b className="market-game-odd">{event.currentScore.split(':')[1]}</b>}
                                                                            </p>
                                                                            <div className="market-game-part-container">
                                                                            <span
                                                                                className="market-game-part">{event.matchStatus}</span>
                                                                                <b>+{event.marketOutcomeCount}</b>
                                                                                <i className=" bc-i-favorite "></i>
                                                                            </div>
                                                                            <div
                                                                                className="market-game-additional-info-container">
                                                                            <span
                                                                                className="market-game-additional-info">{event.currentScore}, {event.scores && <>({event.scores.homeTeam.firstHalf}:{event.scores.awayTeam.firstHalf})</>} {event.matchPlayTime}`</span>
                                                                                <time
                                                                                    className="market-game-additional-info-time">{event.eventDate.split("T")[1].substring(0, 5)}
                                                                                </time>
                                                                            </div>
                                                                            <HighlightedMarketsCard event={event}
                                                                                                    highlightedMarkets={event.highlightedMarkets}></HighlightedMarketsCard>
            
                                                                        </div>*/
                                                                    }) }), void 0)] }), "eventSporMenu2".concat(key)));
                                                    }) }, void 0);
                                            }) }, void 0);
                                    })] }), void 0) }, void 0)] }, void 0)] }, void 0));
}
