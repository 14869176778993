var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Menu from "./widgets/menu";
import SubPageSelected from "./widgets/selected";
import "./account.css";
import { member } from "../../store/login.store";
import { Storage } from "../../lib/localstorege.service";
import { modalServiceSubject } from "../../components/modal/modal";
import { betSlipVisibleSubject } from "../../store/betslip.store";
export default function Account() {
    var params = useParams();
    var navigator = useNavigate();
    useEffect(function () {
        betSlipVisibleSubject.next(false);
        // const leftSideMenu = document.getElementById('leftSideMenu')
        // console.log(leftSideMenu);
        // if (leftSideMenu) {
        //   if (!sportsMenu.isVisible) {
        //     leftSideMenu.style.display = 'none';
        //     leftSideMenu.style.width = '100%';
        //   } else {
        //     leftSideMenu.style.display = 'block';
        //     leftSideMenu.style.width = '50px';
        //   }
        // }
        // const leftSideMenu: any = document.getElementById('leftSideMenu')
        // leftSideMenu.style.display = "none";
        // const rightSideWrapper: any = document.getElementsByClassName('right-side-wrapper');
        // rightSideWrapper[0].style.width = '100%';
        // rightSideWrapper[0].style.marginLeft = '10px';
    }, []);
    var waitForFill = function (myList, tries) {
        if (tries === void 0) { tries = 0; }
        if (myList === undefined && tries < 100) {
            setTimeout(function () {
                waitForFill(member.id, tries += 1);
            }, 100);
        }
        else {
            // console.log(member);
            // console.log(Storage.get('token'));
            if (member.id === undefined || Storage.get('token') === null) {
                setTimeout(function () {
                    modalServiceSubject.next({
                        title: 'Giriş Yapınız',
                        content: 'Bu sayfayı görüntüleyebilmeniz için önce giriş yapmanız gerekir.',
                        confirm: {
                            sure: {
                                label: 'Giriş Yap',
                                action: function () {
                                    navigator('/giris-yap');
                                }
                            },
                            cancel: {
                                label: 'Üye Ol',
                                action: function () {
                                    navigator('/kayit');
                                }
                            }
                        }
                    });
                }, 50);
                // navigator('/')
            }
        }
    };
    if (member.id === undefined && Storage.get('token') !== null) {
        waitForFill(member.id);
    }
    if (member.id) {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "u-i-p-c-body-bc" }, { children: [_jsx(Menu, {}, void 0), _jsx(SubPageSelected, { params: params, subpage: params.subpage }, void 0)] }), void 0) }, void 0));
    }
    return _jsx(_Fragment, {}, void 0);
}
