var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { betSlipVisiblity } from "../../shared/betslip/betslip";
import { ContactUsApi } from './contactus.api';
import { modalServiceSubject } from '../../components/modal/modal';
var ContactUsUser = function () {
    //const navigate = useNavigate();
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var onSubmit = function (data) {
        callRegisterApi(data);
    };
    var callRegisterApi = function (data) {
        data.file = data.file[0];
        ContactUsApi.createContactUsUser(data).then(function (result) {
            if (result.status) {
                modalServiceSubject.next({
                    title: "Teşekkürler!",
                    content: "Bildiriminiz kaydedildi, müşteri temsilcimiz size en yakın sürede geri dönüş sağlayacaktır..",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    return (_jsx("div", __assign({ style: { width: (betSlipVisiblity ? 'calc(100% - 300px)' : '100%') } }, { children: _jsxs("div", __assign({ className: "supportRequests table card page" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Bize Yaz\u0131n" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("div", __assign({ className: "text-justify" }, { children: ["x.com ile alakal\u0131 herhangi bir sorunuzu  M\u00FC\u015Fteri Hizmetlerimize a\u015Fa\u011F\u0131daki form arac\u0131l\u0131\u011F\u0131yla da iletebilirsiniz.", _jsx("br", {}, void 0), " L\u00FCtfen mesaj\u0131n\u0131z i\u00E7inde kredi kart\u0131, banka hesap numaras\u0131 ya da \u015Fifreniz gibi bilgilerinizi vermeyiniz."] }), void 0), _jsx("div", __assign({ className: "table row my-2" }, { children: _jsx("div", { className: "col" }, void 0) }), void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "table row d-flex justify-content-lg-start justify-content-center m-0 mt-2" }, { children: [_jsx("div", __assign({ className: "col-5 col-lg-2 my-1 p-0 col-sm-12 mt-3" }, { children: _jsx("label", { children: "Ki\u015Fisel Bilgileriniz" }, void 0) }), void 0), _jsx("div", __assign({ className: "col-7 col-lg-6 col-sm-12" }, { children: _jsxs("div", __assign({ className: "table row d-flex justify-content-center" }, { children: [_jsxs("div", __assign({ className: "col-6 my-2 p-0" }, { children: [_jsx("input", __assign({ className: "form-control", type: "text" }, register('first_name', { required: true }), { placeholder: "Ad", style: { borderTopRightRadius: "0px", borderBottomRightRadius: "0px" } }), void 0), errors.first_name && errors.first_name.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Ad\u0131n\u0131z gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-6 my-2 p-0" }, { children: [_jsx("input", __assign({ className: "form-control" }, register('last_name', { required: true }), { type: "text", placeholder: "Soyad", style: { borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" } }), void 0), errors.last_name && errors.last_name.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Soyad\u0131n\u0131z gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-12 my-2 p-0" }, { children: [_jsx("input", __assign({ className: "form-control", type: "text" }, register('id_no', { required: true, maxLength: 11, minLength: 11 }), { placeholder: "TC Kimlik No" }), void 0), errors.id_no && errors.id_no.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No gereklidir." }), void 0), errors.id_no && errors.id_no.type === "maxLength" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No 11 haneli olmal\u0131!" }), void 0), errors.id_no && errors.id_no.type === "minLength" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "TC Kimlik No 11 haneli olmal\u0131!" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-12 my-2 p-0" }, { children: [_jsx("input", __assign({ className: "form-control" }, register('email', { required: true }), { type: "email", placeholder: "E - Posta" }), void 0), errors.email && errors.email.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "E-Posta gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-12 my-2 p-0" }, { children: [_jsx("input", __assign({ className: "form-control" }, register('mobile', { required: true, maxLength: 10, minLength: 10 }), { type: "text", placeholder: "Telefon" }), void 0), errors.mobile && errors.mobile.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 gereklidir." }), void 0), errors.mobile && errors.mobile.type === "maxLength" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 10 haneli olmal\u0131!" }), void 0), errors.mobile && errors.mobile.type === "minLength" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "Telefon numaras\u0131 10 haneli olmal\u0131!" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "table row my-2" }, { children: [_jsx("div", __assign({ className: "col-5 col-lg-2 my-1" }, { children: _jsx("label", { children: "Konu Se\u00E7iniz : " }, void 0) }), void 0), _jsxs("div", __assign({ className: "col-7 col-lg-6" }, { children: [_jsxs("select", __assign({}, register('subject', { required: true }), { children: [_jsx("option", __assign({ value: "0" }, { children: "Se\u00E7iniz" }), void 0), _jsx("option", __assign({ value: "60" }, { children: "Kupon" }), void 0), _jsx("option", __assign({ value: "40" }, { children: "Canl\u0131 " }), void 0), _jsx("option", __assign({ value: "20" }, { children: "\u00DCyelik" }), void 0), _jsx("option", __assign({ value: "51" }, { children: "Para Yat\u0131rma" }), void 0), _jsx("option", __assign({ value: "52" }, { children: "Para \u00C7ekme" }), void 0), _jsx("option", __assign({ value: "100" }, { children: "\u0130kramiyeler" }), void 0), _jsx("option", __assign({ value: "150" }, { children: "Referans Kodu" }), void 0), _jsx("option", __assign({ value: "999" }, { children: "Di\u011Fer" }), void 0)] }), void 0), errors.subject && errors.subject.type === "required" &&
                                                    _jsx("span", __assign({ className: "text-validation" }, { children: "Konu gereklidir." }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "table row my-2" }, { children: [_jsx("div", __assign({ className: "col-5 col-lg-2 my-1 hidden-sm " }, { children: _jsx("label", { children: "Mesaj : " }, void 0) }), void 0), _jsxs("div", __assign({ className: "col-7 col-lg-6 col-sm-12" }, { children: [_jsx("textarea", __assign({ className: "form-row w-100 message-box form-control", placeholder: "\u0130letmek istedi\u011Finiz mesaj" }, register('message', {
                                                    required: true,
                                                    maxLength: 1000,
                                                    minLength: 10
                                                })), void 0), errors.message && errors.message.type === "required" &&
                                                    _jsx("span", __assign({ className: "text-validation" }, { children: "Mesaj\u0131n\u0131z gereklidir." }), void 0), errors.message && errors.message.type === "maxLength" &&
                                                    _jsx("span", __assign({ className: "text-validation" }, { children: "Mesaj\u0131n\u0131z en fazla 1000 karakter olmal\u0131! " }), void 0), errors.message && errors.message.type === "minLength" &&
                                                    _jsx("span", __assign({ className: "text-validation" }, { children: "Mesaj\u0131n\u0131z en az 10 karakter olmal\u0131!" }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "table row my-2" }, { children: [_jsx("div", __assign({ className: "col-5 col-lg-2 my-1 d-flex align-items-center" }, { children: _jsx("label", { children: "Dosya : " }, void 0) }), void 0), _jsx("div", __assign({ className: "col-7 col-lg-6" }, { children: _jsx("input", __assign({ type: "file" }, register('file'), { className: "btn w-100 mx-0" }), void 0) }), void 0), _jsxs("div", __assign({ className: "table row my-2" }, { children: [_jsx("div", { className: "col-5 col-lg-2 my-1 hidden-sm" }, void 0), _jsxs("div", __assign({ className: "col-7 col-lg-6 mx-3 col-sm-12" }, { children: [_jsxs("label", __assign({ htmlFor: "kvkk_agreement", className: "mx-2" }, { children: [_jsx("input", __assign({ type: "checkbox" }, register('kvkk', { required: true })), void 0), "\u00A0Ki\u015Fisel Verilerin i\u015Flenmesine ili\u015Fkin \u00A0", _jsx(Link, __assign({ to: "/kisisel-verilerin-korunmasi" }, { children: "KVKK Ayd\u0131nlatma Metni" }), void 0), " 'ni okudum ve kabul ediyorum.", _jsx("small", { children: "*" }, void 0)] }), void 0), errors.kvkk && errors.kvkk.type === "required" &&
                                                            _jsx("span", __assign({ className: "text-validation" }, { children: "KVKK Ayd\u0131nlatma Metnini okuyup kabul etmeniz gereklidir." }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "table row my-2" }, { children: [_jsx("div", { className: "col-5 col-lg-2 my-1" }, void 0), _jsx("div", __assign({ className: "col-7 col-lg-6 d-flex justify-content-end" }, { children: _jsx("input", { type: "submit", className: "btn btn-primary m-0" }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default ContactUsUser;
