var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { eventDetailSubject, marketMapping, sportsbookFilter, sportsbookList, sportsSummery } from "../../store/sportsbook.store";
import { useForceUpdate } from "../../lib/misc.functions";
import _ from "lodash";
import { fillEventStatistics, generateCompetitionGroupList, mobileHighLightedMarket, myHighLightedMarketGroups, setFilterCompetitions, } from "../../services/sportsbook.services";
import { EventDetail, eventDetailVisiblity } from "./eventdetail";
import { getEvent } from "../../services/societyWon.services";
import { Eventlist } from "./widgets/eventlist";
import { highLightedMarketCategories, newMarkets } from "../../definitions/constants/market-categories.constant";
import { societywonEventIdMapping } from "../societywon/subpages/posts";
import { eventEndedStatuses } from "../../definitions/constants/event-result-type";
import { isMobileView } from "../../app";
import SideMenu from "../../shared/sidemenu/sidemenu";
import { useNavigate } from "react-router";
var clearFilters = function () {
    sportsbookFilter.filterKeyword = '';
    sportsbookFilter.filterDays = [];
    sportsbookFilter.filterMBS = [];
    sportsbookFilter.filterCompetition = [];
    sportsbookFilter.filterLiveStream = false;
};
var SportsBook = function () {
    var navigate = useNavigate();
    var params = useParams();
    var forceUpdate = useForceUpdate();
    var width = window.innerWidth;
    var sportSlug = params.sportSlug == 'tumu' ? 'futbol' : params.sportSlug;
    var sportsCompetitions;
    var eventDetail = null;
    // useEffect(() => {
    //     clearFilters();
    //     closeEventDetail();
    //     forceUpdate();
    //     console.log('forceUpdate 1');
    // }, [params.eventType, sportSlug, params.competitionId])
    useEffect(function () {
        if (width > 968) {
            var root = document.getElementById('root');
            root.classList.remove('is-home-page');
            root.classList.remove('betslip-Hidden');
            root.classList.add("page-full-size");
            root.classList.add("prematch");
            root.classList.add("sportsbook");
            root.classList.add("betslip-Fixed");
            root.classList.add("compact-footer");
        }
        return function () {
        };
    }, []);
    // if (width < 969 && betSlipVisiblity) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            if (res && res === 'toggle') {
                eventDetailVisiblity.visible = false;
                closeEventDetail();
                forceUpdate();
            }
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
        };
    }, []);
    var openEventDetail = function (event) {
        if (event) {
            eventDetailSubject.next(event);
        }
        if (isMobileView) {
            forceUpdate();
            window.scrollTo(0, 0);
        }
    };
    var closeEventDetail = function () {
        eventDetailSubject.next(false);
        eventDetailVisiblity.visible = false;
        document.body.style.overflow = 'unset';
        if (isMobileView) {
            forceUpdate();
        }
    };
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            navigate("/sports/" + sportSlug + '/uzunvadeli');
            // document.body.style.overflow = 'unset';
        }
        else {
            navigate("/sports/" + sportSlug + '/tumu');
            // document.body.style.overflow = 'unset';
        }
    };
    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
        if (sportSlug === el.sportSlug) {
            if ((params.eventType === 'tumu' && Number(el.eventType) === 1) ||
                (params.eventType === 'canli' && Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                (params.eventType === 'uzunvadeli' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                return el;
            }
        }
        else if (sportSlug == null && Number(el.eventType) !== 2) {
            return el;
        }
    });
    // console.log("originSportsbookList>>", originSportsbookList)
    var shownSportsBook = [];
    // fixme : performans iyileştirme lazım
    shownSportsBook = originSportsbookList.filter(function (el) {
        var filteredElement = el;
        if ((Number(filteredElement.isLiveEvent) === 0 || (Number(filteredElement.bettingPhase) === 0 && eventEndedStatuses.includes(filteredElement.liveScoreStatus))) &&
            marketMapping[String(filteredElement.eventId)].filter(function (m) { return Number(m.marketStatus) !== 0; }).length === 0) {
            el.isRemoved = true;
            filteredElement = null;
        }
        else {
            el.isRemoved = false;
        }
        if (filteredElement && sportsbookFilter.filterCompetition.length > 0) {
            if (sportsbookFilter.filterCompetition.indexOf(el.competitionId.toString()) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterRapid === true) {
            if (marketMapping[filteredElement.eventId].filter(function (el) { return newMarkets.includes(el.marketType + '-' + el.marketSubType) && (el.marketStatus === 1 || el.marketStatus < 0); }).length > 0) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterCustomRatio === true) {
            if (el.hasCustomratio === true) {
                filteredElement = el;
            }
            else if (el.additionalBonus === true) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterMBS.length > 0) {
            if (sportsbookFilter.filterMBS.indexOf(el.minCombiCount) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterDays.length > 0) {
            if (sportsbookFilter.filterDays.indexOf(el.eventDate.substring(0, 10)) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveStream) {
            if (el.liveStreamAvailable === 1 || el.liveStreamAvailable === 2) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveEvent) {
            if (Number(el.isLiveEvent) === 1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveEvent) {
            if (Number(el.isLiveEvent) === 1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterAlarmSet) {
            if (el.alarmStatus === true) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterKeyword.length > 1) {
            if (el.eventName.toLowerCase().indexOf(sportsbookFilter.filterKeyword.toLowerCase()) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && params.eventType === 'canli') {
            if (Number(filteredElement.isLiveEvent) !== 1) {
                filteredElement = null;
            }
        }
        if (filteredElement) {
            if (Number(filteredElement.bettingPhase) != 0) {
                filteredElement = null;
            }
        }
        if (filteredElement) {
            return filteredElement;
        }
    });
    // console.log("shownSportsBook>>>", shownSportsBook);
    useEffect(function () {
        var _a;
        if (params.eventId) {
            var reloadEvent = sportsbookList.find(function (el) { return Number(el.eventId) === Number(params.eventId); });
            if (reloadEvent) {
                openEventDetail(reloadEvent);
            }
            else {
                getEvent(Number(params.eventId)).then(function (event) {
                    event.isEnded = true;
                    societywonEventIdMapping[Number(event.eventId)] = event;
                    marketMapping[event.eventId] = event.markets;
                    fillEventStatistics([Number(params.eventId)]).finally(function () {
                        openEventDetail(societywonEventIdMapping[Number(params.eventId)]);
                    });
                });
            }
        }
        else {
            if (params.competitionId) {
                (_a = sportsbookFilter.filterCompetition).push.apply(_a, params.competitionId.split(','));
            }
            closeEventDetail();
        }
    }, [params.eventId, params.competitionId]);
    var marketGroup = myHighLightedMarketGroups[sportSlug !== null && sportSlug !== void 0 ? sportSlug : 'futbol'];
    var searchTimer;
    useEffect(function () {
        if (!isMobileView) {
            try {
                // if (eventDetail === null) {
                //     eventDetail = shownSportsBook[0];
                if (params.eventId == null) {
                    openEventDetail(shownSportsBook[0]);
                }
                if (params.competitionId == null) {
                    setFilterCompetitions(shownSportsBook[0].competitionId);
                    forceUpdate();
                }
                else {
                    setFilterCompetitions(params.competitionId);
                    forceUpdate();
                }
                // }
            }
            catch (e) {
            }
        }
        else {
            if (!sportsbookFilter.selectedCompetitionGroup.competitionGroupName) {
                try {
                    var sportsCompetitions_1 = generateCompetitionGroupList(originSportsbookList, [sportSlug]);
                    sportsbookFilter.selectedCompetitionGroup = sportsCompetitions_1.filter(function (el) {
                        return el.competitionGroupName == shownSportsBook[0].competitionGroupName;
                    });
                }
                catch (e) {
                }
            }
            if (!mobileHighLightedMarket.selected) {
                mobileHighLightedMarket.selected = highLightedMarketCategories[params.sportSlug || 'futbol'][0];
            }
        }
    }, []);
    return (_jsxs(_Fragment, { children: [!isMobileView && _jsxs("div", __assign({ id: "sportsbook-container-id", className: "sportsbook-container multi-column-view  " }, { children: [_jsx(SideMenu, { competitionId: params.competitionId, sportSlug: params.sportSlug, eventType: params.eventType }, void 0), _jsx("div", __assign({ className: "sportsbook-center-section" }, { children: _jsxs("div", __assign({ className: "sportsbook-center-section-scroll" }, { children: [params.eventType != 'canli' && _jsx("div", __assign({ className: "prematch-page-bc" }, { children: _jsxs("div", __assign({ className: "multi-column-resize-block" }, { children: [_jsxs("div", __assign({ className: "comp-name-fav-row-bc" }, { children: [_jsx("i", { className: "bc-i-".concat(sportSlug, " game-d-c-h-c-r-sport-icon") }, void 0), shownSportsBook.length > 0 && _jsx("img", { width: 15, height: 15, style: { margin: "0 8px 0px" }, src: "https://cdn.1xwon.com/images/country_icons/".concat(shownSportsBook[0].competitionGroupName === "IIIskoçya" ? 'Scotland' : shownSportsBook[0].countryName, ".png") }, void 0), _jsx("p", __assign({ className: "comp-title-w-bc ellipsis" }, { children: shownSportsBook && shownSportsBook.length > 0 && shownSportsBook[0].competitionGroupName }), void 0), _jsx("i", { className: "comp-fav-icon-bc bc-i-favorite " }, void 0)] }), void 0), _jsxs("div", __assign({ className: "multi-column-container" }, { children: [_jsx("i", { id: "multi-column-to-icon-id", className: "multi-column-to-icon bc-i-block-to-right", onClick: function () {
                                                            var sportsBookContainer = document.getElementById('sportsbook-container-id');
                                                            var multiColumnToIcon = document.getElementById('multi-column-to-icon-id');
                                                            if (multiColumnToIcon.classList.value.includes("bc-i-block-to-right")) {
                                                                multiColumnToIcon.classList.remove("bc-i-block-to-right");
                                                                multiColumnToIcon.classList.add("bc-i-block-to-left");
                                                                sportsBookContainer.classList.add("multi-column-view-expanded");
                                                            }
                                                            else {
                                                                multiColumnToIcon.classList.add("bc-i-block-to-right");
                                                                multiColumnToIcon.classList.remove("bc-i-block-to-left");
                                                                sportsBookContainer.classList.remove("multi-column-view-expanded");
                                                            }
                                                        } }, void 0), _jsxs("ul", __assign({ className: "multi-column-header" }, { children: [_jsx("li", {}, "multi-column-key--1"), marketGroup.map(function (el, key) {
                                                                return (_jsxs(React.Fragment, { children: [key != 0 && _jsx("li", { className: "multi-column-base" }, void 0), _jsxs("li", __assign({ className: "".concat(el.priority == 0 ? 'multi-column-WINNER' : 'multi-column-HANDICAP', " multi-column-count-").concat(el.outcomeCount) }, { children: [_jsx("p", { children: el.name }, void 0), _jsx("div", { children: el.labels.map(function (marketLabel, marketLabelKey) {
                                                                                        return _jsx("b", { children: marketLabel }, key + "-marketLabel-" + marketLabelKey);
                                                                                    }) }, void 0)] }), void 0)] }, "marketGroup-".concat(key)));
                                                            }), _jsx("li", {}, "multi-column-key--2")] }), void 0), _jsx(Eventlist, { openEventDetail: openEventDetail, sportsbookFilter: sportsbookFilter, params: params, shownSportsBook: shownSportsBook }, void 0)] }), void 0)] }), void 0) }), void 0), _jsx(EventDetail, { eventType: params.eventType, sportSlug: params.sportSlug, closeEventDetail: closeEventDetail, openEventDetail: openEventDetail }, void 0)] }), void 0) }), void 0)] }), void 0), isMobileView && _jsxs(_Fragment, { children: [!eventDetailVisiblity.visible && _jsxs("div", __assign({ className: "prematch-page-bc" }, { children: [_jsx("div", __assign({ className: "horizontal-sl-list-container", "data-scroll-lock-scrollable": "" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list pp-sport-type-holder-bc ext-2" }, { children: sportsSummery.map(function (item, key) {
                                        return _jsxs("div", __assign({ id: key, title: item.sportSlug, "data-badge": "", onClick: function () {
                                                mobileHighLightedMarket.selected = null;
                                                goToUrl(item.sportSlug, item.eventType);
                                            }, className: "horizontal-sl-item-bc accordion-button ".concat(item.sportSlug, " ").concat(item.sportSlug == sportSlug ? 'active' : '', " ") }, { children: [_jsx("i", { className: "horizontal-sl-icon-bc bc-i-default-icon bc-i-".concat(item.sportSlug) }, void 0), _jsxs("p", __assign({ className: "horizontal-sl-title-bc" }, { children: [item.sportName.replace('Hokeyi', 'Hok.').replace('Sporları', 'Spr.'), " "] }), void 0), _jsx("span", __assign({ className: "horizontal-sl-count-bc" }, { children: item.count }), void 0)] }), "sports-" + key);
                                    }) }), void 0) }), void 0), !params.competitionId ?
                                _jsx(SideMenu, { eventType: params.eventType, sportSlug: sportSlug }, void 0)
                                : _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "back-nav-bc" }, { children: [_jsx("i", { className: "back-nav-icon-bc bc-i-round-arrow-left", onClick: function () {
                                                        navigate("/sports/" + sportSlug + '/tumu/');
                                                    } }, void 0), sportsbookFilter.selectedCompetitionGroup.competitionGroupName && _jsxs("span", __assign({ className: "back-nav-title-bc ellipsis" }, { children: [sportsbookFilter.selectedCompetitionGroup.sportSlug, " / ", sportsbookFilter.selectedCompetitionGroup.competitionGroupName] }), void 0)] }), void 0), sportsbookFilter.selectedCompetitionGroup.competitionGroupName &&
                                            _jsxs("div", __assign({ className: "comp-name-fav-row-bc " }, { children: [_jsx("div", __assign({ className: "comp-select-row-bc" }, { children: _jsx("div", __assign({ className: "form-control-bc select  filled" }, { children: _jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("select", __assign({ className: "form-control-select-bc ellipsis", onChange: function (el) {
                                                                            navigate("/sports/" + sportSlug + '/tumu/' + el.target.value);
                                                                        } }, { children: Object.keys(sportsbookFilter.selectedCompetitionGroup.competitions).map(function (comp, key) {
                                                                            return _jsx("option", __assign({ value: sportsbookFilter.selectedCompetitionGroup.competitions[comp][0].competitionId }, { children: comp }), void 0);
                                                                        }) }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("i", { className: "comp-fav-icon-bc bc-i-favorite " }, void 0)] }), void 0), _jsx("div", __assign({ className: "market-filter-scrollable-navigation-bc-holder" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list-container", "data-scroll-lock-scrollable": "" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list " }, { children: highLightedMarketCategories[params.sportSlug || 'futbol'].map(function (marketGroup, key) {
                                                        return _jsx("div", __assign({ title: "Ma\u00E7 Sonucu", "data-badge": "", onClick: function () {
                                                                mobileHighLightedMarket.selected = marketGroup;
                                                                forceUpdate();
                                                            }, className: "horizontal-sl-item-bc accordion-button  ".concat(mobileHighLightedMarket.selected.pre === marketGroup.pre ? 'active' : '') }, { children: _jsx("p", __assign({ className: "horizontal-sl-title-bc" }, { children: marketGroup.name }), void 0) }), key);
                                                    }) }), void 0) }), void 0) }), void 0), _jsx(Eventlist, { openEventDetail: openEventDetail, sportsbookFilter: sportsbookFilter, params: params, shownSportsBook: shownSportsBook }, void 0)] }, void 0)] }), void 0), _jsx(EventDetail, { eventType: params.eventType, sportSlug: params.sportSlug, closeEventDetail: closeEventDetail, openEventDetail: openEventDetail }, void 0)] }, void 0)] }, void 0));
};
export default SportsBook;
