var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Lock from "../../../assets/feather/lock.svg";
import { addToBetSlip } from "../../../services/betslip.services";
import _ from "lodash";
import { marketNameTransform } from "../../../services/sportsbook.services";
import { handicappedMarkets } from "../../../definitions/constants/market-categories.constant";
import { isMobileView } from "../../../app";
export function Odd(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    var market = props.market;
    if (market) {
        var event_1 = props.event;
        var marketOutcome = market.marketOutcome;
        if (event_1.eventType === '2') {
            marketOutcome = _.orderBy(market.marketOutcome.filter(function (el) { return el.fixedOddsWeb > 1; }), function (o) {
                return Number(o.fixedOddsWeb);
            });
        }
        if (isMobileView) {
            if (props.showOutcomeName) {
                return _jsx(_Fragment, { children: marketOutcome.map(function (outcome, key) {
                        return _jsxs("div", __assign({ className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : ''), onClick: function () {
                                addToBetSlip(event_1, market, outcome);
                            } }, { children: [props.showOutcomeName && _jsxs("span", __assign({ className: "market-name-bc ellipsis" }, { children: [outcome.outcomeName, " ", handicappedMarkets.includes(market.marketType + '-' + market.marketSubType) || (market.marketName.includes("Maçın Geri Kalanını Kim Kazanır")) || (market.marketName.includes("Sıradaki Golü Kim Atar")) ? marketNameTransform(outcome.outcomeName, market.specialOddsValue, market) : " ".concat((market.specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '')] }), void 0), _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOddsWeb) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0)] }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                    }) }, void 0);
            }
            else {
                return _jsx(_Fragment, { children: marketOutcome.map(function (outcome, key) {
                        return _jsx("div", __assign({ className: "c-odd-bc", onClick: function () {
                                addToBetSlip(event_1, market, outcome);
                            } }, { children: _jsx("div", __assign({ className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : '') }, { children: _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOddsWeb) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                    }) }, void 0);
            }
        }
        else {
            return (_jsx(_Fragment, { children: marketOutcome.map(function (outcome, key) {
                    return _jsxs("div", __assign({ onClick: function () {
                            addToBetSlip(event_1, market, outcome);
                        }, className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : '') }, { children: [props.showOutcomeName && _jsxs("span", __assign({ className: "market-name-bc ellipsis" }, { children: [outcome.outcomeName, " ", handicappedMarkets.includes(market.marketType + '-' + market.marketSubType) || (market.marketName.includes("Maçın Geri Kalanını Kim Kazanır")) || (market.marketName.includes("Sıradaki Golü Kim Atar")) ? marketNameTransform(outcome.outcomeName, market.specialOddsValue, market) : " ".concat((market.specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '')] }), void 0), _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOddsWeb) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0)] }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                }) }, void 0));
        }
    }
    else {
        return _jsx(_Fragment, {}, void 0);
    }
}
