import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import DepositMoneys from "../../../depositmoney/depositmoney";
export default function DepositMoney() {
    // const [depositMoneyData, setDepositMoneyData] = useState<any>(depositMoneyList)
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    // useMemo(() => {
    //     FinancalTransactionsApi.depositMoney().then(
    //         (result: any) => {
    //             setDepositMoneyData(result)
    //         })
    // }, [])
    return (_jsx(DepositMoneys, {}, void 0));
}
