var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { HighlightedMarkets } from "./highlightedmarkets";
import { calcMinutesLest, ServerDate, timeFormat, useForceUpdate } from "../../../lib/misc.functions";
import { LiveScoreStatusType } from "../../../definitions/constants/event-status-type.constant";
import { eventEndedStatuses } from "../../../definitions/constants/event-result-type";
import { eventMapping, eventUpdateSubject, marketMapping } from "../../../store/sportsbook.store";
import { betSlipActionSubject } from "../../../store/betslip.store";
import { isAndroid, isIOS, isMacOs, isWindows, osVersion } from 'react-device-detect';
import BellOn from "../../../assets/feather/bell.svg";
import { customStyles } from "../../../definitions/constants/modal.constant";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { ApiService } from "../../../lib/http.service";
import { rapidMarkets } from "../../../definitions/constants/market-categories.constant";
import { isMobileView } from "../../../app";
var removerTimer = {};
var updateTimer = {};
export function Event(props) {
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    // console.log(props.event.eventId)
    // const highlightedMarkets = props.highlightedMarkets;
    var eventType = props.eventType;
    var event = props.event;
    var parentEvent = props.parentEvent;
    var setShowEventDetail = props.context;
    var showEventDetail = props.showEventDetail;
    var fromOutSource = props.fromOutSource;
    var isDuelloCombination = props.isDuelloCombination;
    var openAsNewPage = false;
    if ((isAndroid && Number(osVersion.split('.')[0]) >= 9) || isMacOs || isWindows ||
        (isIOS && Number(osVersion.split('.')[0]) >= 15)) {
        openAsNewPage = true;
    }
    var width = window.innerWidth;
    if (event.liveScoreStatus && event.matchPlayTime !== undefined && event.matchPlayTime !== null && event.matchPlayTime !== '' && event.matchPlayTime !== ' ' && Number(event.matchPlayTime) >= 0) {
        var time_statuses = ["520", "521", "522", "523", "700"];
        if (time_statuses.includes(event.liveScoreStatus.toString())) {
            event.minute = event.matchPlayTime + "'";
        }
        else {
            event.minute = LiveScoreStatusType[Number(event.liveScoreStatus)];
        }
    }
    var durationInMinutes = event.sportSlug === 'futbol' ? 85 : 35;
    var diffMin = ServerDate().getTime() - new Date(event.eventDate).getTime();
    var exStatus = event.isRemoved;
    if (event && ((Number(event.isLiveEvent) === 1 && eventEndedStatuses.indexOf(event.liveScoreStatus) !== -1 && diffMin > (durationInMinutes * 60000)) ||
        (Number(event.isLiveEvent) === 0 && new Date(event.eventDate) < ServerDate()))) {
        event.isRemoved = true;
        if (exStatus !== event.isRemoved) {
            forceUpdate();
        }
    }
    else {
        event.isRemoved = false;
    }
    // useEffect(() => {
    //     const loginSubject_subscription: Subscription = loginSubject.subscribe((res:any) => {
    //         if (member && res === 'addition_ratio') {
    //             forceUpdate()
    //         }
    //     });
    //     return () => {
    //         loginSubject_subscription.unsubscribe();
    //     }
    // }, []);
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (typeof events === 'object' && event) {
                if (['toggle', 'remove'].includes(events.action)) {
                    if (events.betSlip.sportEvent) {
                        if (events.betSlip.sportEvent.eventId === event.eventId) {
                            forceUpdate();
                        }
                    }
                }
                else if (events.action === 'clear') {
                    if (events.betSlip.includes(event.eventId)) {
                        forceUpdate();
                    }
                }
            }
        });
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (events.includes('duello')) {
                if (events.includes(String(event.eventId)) || events.includes(Number(event.eventId))) {
                    forceUpdate();
                }
            }
            else {
                if (events.includes(String(event.eventId)) || events.includes(Number(event.eventId))) {
                    if (updateTimer[String(event.eventId)]) {
                        clearTimeout(updateTimer[String(event.eventId)]);
                    }
                    updateTimer[String(event.eventId)] = setTimeout(function () {
                        forceUpdate();
                    }, 300);
                }
            }
        });
        return function () {
            if (removerTimer[String(event.eventId)]) {
                clearTimeout(removerTimer[String(event.eventId)]);
            }
            if (updateTimer[String(event.eventId)]) {
                clearTimeout(updateTimer[String(event.eventId)]);
            }
            betSlipActionSubject_subscription.unsubscribe();
            eventUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    // console.log(isDuelloCombination,  parentEvent)
    if (isDuelloCombination && parentEvent.showSubs !== true) {
        return _jsx(_Fragment, {}, void 0);
    }
    var bettingPhase = Number(event.bettingPhase);
    if (event.isRemoved === true || eventEndedStatuses.includes(Number(event.liveScoreStatus))) {
        // console.log("Kaldır")
        removerTimer[String(event.eventId)] = setTimeout(function () {
            // console.log("hadii")
            var ev = document.getElementById('eventId_' + event.eventId);
            // console.log(ev)
            if (ev) {
                try {
                    var list = ev.getElementsByClassName('event-card');
                    // console.log("list", list)
                    if (list && list.length > 0 && list[0]) {
                        // console.log("set Style")
                        list[0].style.display = 'none';
                    }
                    var nextEvent = ev.nextSibling;
                    var haveGroupTitle = nextEvent.getElementsByClassName('groupHeader');
                    if (haveGroupTitle.length > 0) {
                        ev.style.display = 'none';
                    }
                }
                catch (e) {
                }
            }
        }, 100);
    }
    var toggleEventCombinations = function (event) {
        event.showSubs = event.showSubs !== true ? true : false;
        var trigEvent = [event.eventId,];
        try {
            var event1 = eventMapping[String(event.eventClosedCombination[0].eventId)];
            if (event1) {
                trigEvent.push(String(event1.eventId));
            }
        }
        catch (e) {
            console.log(e);
        }
        try {
            var event2 = eventMapping[String(event.eventClosedCombination[1].eventId)];
            if (event2) {
                trigEvent.push(String(event2.eventId));
            }
        }
        catch (e) {
            console.log(e);
        }
        // if(event1.toggleCombination!==true){
        //     event1.toggleCombination = true;
        // }else{
        //     event1.toggleCombination = false;
        // }
        //
        // if(event2.toggleCombination!==true){
        //     event2.toggleCombination = true;
        // }else{
        //     event2.toggleCombination = false;
        // }
        // console.log(trigEvent)
        // forceUpdate();
        trigEvent.push('duello');
        eventUpdateSubject.next(trigEvent);
    };
    var haveRapidMarket = marketMapping[String(event.eventId)].filter(function (el) { return rapidMarkets.includes(el.marketType + '-' + el.marketSubType) && (el.marketStatus === 1 || el.marketStatus < 0); });
    if (isMobileView) {
        return _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "sw-container-bc ", style: { transform: "translateX(0px)" } }, { children: [_jsx("div", __assign({ className: "c-segment-holder-bc markets " }, { children: _jsxs("div", __assign({ className: "c-row-bc bordered" }, { children: [_jsxs("div", __assign({ className: "c-teams-bc", onClick: function () {
                                            if (fromOutSource || openAsNewPage) {
                                                navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                                            }
                                            else {
                                                setShowEventDetail(event);
                                            }
                                            // forceUpdate();
                                        } }, { children: [_jsx("div", __assign({ className: "c-teams-row-bc" }, { children: _jsx("span", __assign({ className: "c-team-info-team-bc team", title: "Konyaspor" }, { children: event.eventParticipant[0].participantName }), void 0) }), void 0), _jsx("div", __assign({ className: "c-teams-row-bc" }, { children: _jsx("span", __assign({ className: "c-team-info-team-bc team", title: "Sivasspor" }, { children: event.eventParticipant[1].participantName }), void 0) }), void 0)] }), void 0), _jsx(HighlightedMarkets, { style: "border:1px solid #000;", event: event, highlightedMarkets: event.highlightedMarkets }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "c-segment-holder-bc info", onClick: function () {
                                if (fromOutSource || openAsNewPage) {
                                    navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                                }
                                else {
                                    setShowEventDetail(event);
                                }
                                // forceUpdate();
                            } }, { children: _jsxs("div", __assign({ className: "c-info-bc" }, { children: [_jsxs("div", __assign({ className: "c-info-content-bc" }, { children: [_jsx("i", { className: "info-live-prematch-icon-bc bc-i-prematch" }, void 0), _jsx("time", __assign({ className: "c-info-time-bc" }, { children: timeFormat(event.eventDate) }), void 0), _jsx("div", { className: "c-info-score-wrapper" }, void 0)] }), void 0), _jsx("div", __assign({ className: "c-info-more-bc" }, { children: _jsxs("span", __assign({ className: "c-info-icon-bc c-info-markets-count-bc" }, { children: ["+", event.marketOutcomeCount] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "swipe-fav-container-bc ", style: { transform: "translateX(60px)" } }, { children: _jsx("i", { className: "c-info-icon-bc bc-i-favorite" }, void 0) }), void 0)] }, void 0);
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ onClick: function () {
                        console.log(fromOutSource);
                        console.log(openAsNewPage);
                        if (fromOutSource || openAsNewPage) {
                            console.log('ifde');
                            navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                        }
                        else {
                            console.log('elsede');
                            setShowEventDetail(event);
                        }
                        forceUpdate();
                    } }, { children: [_jsxs("div", __assign({ className: "multi-column-teams" }, { children: [_jsx("div", __assign({ className: "multi-column-single-team" }, { children: _jsx("p", __assign({ className: "ellipsis" }, { children: event.eventParticipant[0].participantName }), void 0) }), void 0), _jsx("div", __assign({ className: "multi-column-single-team" }, { children: _jsx("p", __assign({ className: "ellipsis" }, { children: event.eventParticipant[1].participantName }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "multi-column-time-icon" }, { children: [_jsx("time", __assign({ className: "" }, { children: timeFormat(event.eventDate) }), void 0), _jsx("div", __assign({ className: "multi-column-icons" }, { children: _jsx("i", { className: " bc-i-favorite " }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx(HighlightedMarkets, { style: "border:1px solid #000;", event: event, highlightedMarkets: event.highlightedMarkets }, void 0), _jsxs("li", __assign({ onClick: function () {
                        if (fromOutSource || openAsNewPage) {
                            navigate('/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                        }
                        else {
                            setShowEventDetail(event);
                        }
                        forceUpdate();
                    } }, { children: ["+", event.marketOutcomeCount, _jsx("i", { className: "bc-i-small-arrow-right" }, void 0)] }), void 0)] }, void 0));
    }
}
export function ScoreAlarmSubscription(props) {
    var _a = useState(false), modalVisible = _a[0], setModalVisible = _a[1];
    var apiService = new ApiService();
    var event = props.event;
    var setScoreAlarm = function (event) {
        setModalVisible(event);
    };
    var closeModal = function () {
        setModalVisible(false);
    };
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, reset = _b.reset;
    var onSubmit = function (data) {
        // let obje = {
        //     matchBegin: data.matchBegin ? true : false,
        //     matchBeforeBegin: data.matchBeforeBegin ? true : false,
        //     goals: data.goals ? true : false,
        //     redCards: data.redCards ? true : false,
        //     periods: data.periods ? true : false,
        //     matchEnded: data.matchEnded ? true : false,
        //     event_id: event.eventId,
        //     token: Storage.get('token'),
        //     action: event.alarmStatus ? 'remove' : 'add'
        // }
        // apiService.start('post', env.scores + '/api/subscribe-event/', obje, true).then(
        //     (result: any) => {
        //         if (result.status === 'added') {
        //             closeModal();
        //             modalServiceSubject.next({
        //                 title: 'Alarm kuruldu!',
        //                 timeout: 3000,
        //                 content: 'Karşılaşmaya ait bildirim alarmu kuruldu, eğer bildirim almayı durdurmak isterseniz, kurulum ekranından "İptal Et" butonuna basarak iptal edebilirsiniz.'
        //             })
        //             event.alarmStatus = true;
        //         } else if (result.status === 'removed') {
        //             event.alarmStatus = false;
        //             closeModal();
        //         } else {
        //             modalServiceSubject.next({
        //                 title: 'Bir hata oluştu',
        //                 timeout: 3000,
        //                 content: result.message
        //             })
        //         }
        //         eventUpdateSubject.next([event.eventId])
        //     }
        // )
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: " subscribe " + (event.alarmStatus ? 'active' : ''), style: { background: event.alarmStatus ? 'var(--color-xwon)' : '' }, onClick: function () {
                    setScoreAlarm(event);
                } }, { children: _jsx("img", { title: "Ma\u00E7 Takip", src: BellOn, width: 13, className: 'invert-color', style: { filter: event.alarmStatus ? "invert(1)" : '' } }, void 0) }), void 0), _jsxs(Modal, __assign({ isOpen: modalVisible !== false ? true : false, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h3", { children: "Kar\u015F\u0131la\u015Fma Bildirimi" }, void 0) }), void 0), _jsx("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", __assign({ className: "modal-body", style: { height: ('auto') } }, { children: [!event.alarmStatus ? _jsx(_Fragment, { children: _jsxs("ul", __assign({ className: "competitionFilterList", style: { height: "auto" } }, { children: [Number(event.bettingPhase) === 0 && Number(calcMinutesLest(event.eventDate)) > 10 ?
                                                _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ defaultChecked: true, disabled: event.alarmStatus }, register('matchBeforeBegin'), { type: "checkbox", 
                                                                        // checked={sportsbookFilter.filterCompetition.indexOf(el[1][0].competitionId) !== -1}
                                                                        onChange: function () {
                                                                            // setFilterCompetitions(el[1][0].compeWtitionId);
                                                                            // forceUpdate();
                                                                        } }), void 0), "Ma\u00E7 Hat\u0131rlatma"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) :
                                                null, Number(event.bettingPhase) === 0 && Number(calcMinutesLest(event.eventDate)) > 1 ?
                                                _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ type: "checkbox", disabled: event.alarmStatus, defaultChecked: true }, register('matchBegin'), { 
                                                                        // checked={sportsbookFilter.filterCompetition.indexOf(el[1][0].competitionId) !== -1}
                                                                        onChange: function () {
                                                                            // setFilterCompetitions(el[1][0].competitionId);
                                                                            // forceUpdate();
                                                                        } }), void 0), "Ma\u00E7 ba\u015Flad\u0131"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) :
                                                null, ["futbol"].includes(event.sportSlug) ? _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ type: "checkbox", disabled: event.alarmStatus, defaultChecked: true }, register('goals')), void 0), "Gol"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), event.sportSlug === "futbol" ? _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ type: "checkbox", disabled: event.alarmStatus, defaultChecked: true }, register('redCards')), void 0), "K\u0131rm\u0131z\u0131 Kart"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), (event.sportSlug == "futbol" && Number(event.bettingPhase) !== 0 && Number(event.matchPlayTime) < 45) || ["futbol", 'basketbol'].includes(event.sportSlug) ?
                                                _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ type: "checkbox", disabled: event.alarmStatus, defaultChecked: true }, register('periods')), void 0), "\u0130lk yar\u0131 skoru / Peryod bilgisi"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) : null, _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", __assign({ type: "checkbox", disabled: event.alarmStatus, defaultChecked: true }, register('matchEnded')), void 0), "Ma\u00E7 bitti"] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "modal-footer" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [!event.alarmStatus ? _jsx(_Fragment, { children: _jsx("button", __assign({ type: "submit", className: "btn btn-primary" }, { children: "Ayarla" }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsx("button", __assign({ type: "submit", className: "btn btn-warning" }, { children: "\u0130ptal Et" }), void 0) }, void 0), _jsx("div", __assign({ className: "btn btn-default", onClick: function () {
                                                    closeModal();
                                                } }, { children: "Vazge\u00E7" }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0)] }, void 0));
}
